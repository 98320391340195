export const ABI_URL = {
	"home":{
		au:"https://www.abiinteriors.com.au",
		nz:"https://www.abiinteriors.co.nz",
		uk:"https://www.abiinteriors.co.uk",
		title:"Quality Kitchen and Bathroom Fixtures"
	},
	"catalogue":{
		au:"https://www.abiinteriors.com.au/catalogue/",
		nz:"https://www.abiinteriors.co.nz/catalogue/",
		uk:"https://www.abiinteriors.co.uk/catalogue-2022/",
		title:"Browse Our Catalogues"
	},
	"shop-colour-sample-pack":{
		au:"https://www.abiinteriors.com.au/product-category/product-samples/",
		nz:"https://www.abiinteriors.co.nz/product-category/product-samples/",
		uk:"https://www.abiinteriors.co.uk/product-category/product-samples/",
		title:"Explore Our Colour Samples"
	},
	"trade-partner-application":{
		au:"https://www.abiinteriors.com.au/work-with-us/trade-application",
		nz:"https://www.abiinteriors.co.nz/trade-application/",
		uk:"https://www.abiinteriors.co.uk/projects/",
		title:"Join Our Personalised Trade Program"
	},
	"warranty":{
		au:"https://www.abiinteriors.com.au/help-centre/warranty-and-product-enquiry/",
		nz:"https://www.abiinteriors.co.nz/warranty/",
		uk:"https://www.abiinteriors.co.uk/warranty/",
		title:"Lodge a Claim or View Our Warranty Terms & Conditions"
	}
}