import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../views/LandingPage";

const routes = [
	{ path: '/', component: LandingPage },
	{ path: '/catalogue', component: LandingPage },
	{ path: '/product-samples', component: LandingPage },
	{ path: '/trade-application', component: LandingPage },
	{ path: '/warranty-and-product-enquiry', component: LandingPage }
]

const router = createRouter({
	history:createWebHistory(process.env.BASE_URL),
	routes
})

export default router;