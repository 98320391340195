<template>
	<nav>
		<div class="nav-body">
			<div class="nav-body__logoWrapper">
				<div>
					<img class="nav-logo" src="@/assets/imgs/Logo.png" alt="abi-logo">
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name:"NavBar"
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
	nav {
		width:100vw;
		box-sizing: border-box;
		height: 88px;
		display: flex;
		justify-content: center;
		background-color: white;
		border-bottom: 1px $charcoal_30_color solid;
		position: fixed;
		top: 0;
		z-index: 2;
		.nav-body {
			width: $content_width;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.nav-body__logoWrapper {
				width: 50%;
				padding-left: 40px;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				div {
					width: 550px;
					.nav-logo {
						width:70px;
					}
				}
			}
		}
	}

	@include media($phone){
		nav{
			.nav-body {
				.nav-body__logoWrapper {
					div {
						width: 100%;
						.nav-logo {
							width: 60px;
						}
					}
				}
			}
		}
	}
</style>