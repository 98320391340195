<template>
	<div class="lp-body">
		<NavBar/>
		<div class="lp-content">
			<SelectLocations/>
			<HeroImg class="lp-heroImg"/>
		</div>
	</div>
</template>

<script>
import SelectLocations from "@/components/SelectLocations"
import HeroImg from "@/components/HeroImg"
import NavBar from "@/components/NavBar"
export default {
	name:"LandingPage",
	components:{
		SelectLocations,
		HeroImg,
		NavBar
	}
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
	.lp-body {
		display: flex;
		justify-content: center;
		.lp-content{
			width: $content_width;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: $navbar_height;
			height: 100vh;
			min-height: 900px;
		}
	}

	@include media($tablet-protrait){
		.lp-body {
			width: 100%;
			.lp-content {
				flex-direction: column;
				height: auto;
				.lp-heroImg {
					display: none;
				}
			}
		}
	}

	@include media($phone){
		.lp-body {
			width: 100%;
			.lp-content {
				width: 100%;
				flex-direction: column;
				height: auto;
				.lp-heroImg {
					display: none;
				}
			}
		}
	}
</style>

