<template>
	<div class="hi-body">
		<img class="hi-body__img" src="https://cdn.bfldr.com/8266KQUL/at/93xzxpwwcqkw6j64vj2w6qbt/Kerrie-Ann-19-Web.jpg?auto=webp&format=jpg" alt="featureImg">
		<div id="animate-bg" class="hi-body__bg"></div>
	</div>
</template>

<script>
export default {
	name:"HeroImg",
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
	.hi-body {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		width: 50%;
		height: 100%;
		position: relative;
		.hi-body__img {
			margin-top: 85px;
			animation: topFadeIn 1000ms alternate;
			width: 70%;
			max-width: 400px;
			z-index: 1;
			position: relative;
			border-top-left-radius:1000px;
			border-top-right-radius:1000px;
		}
		.hi-body__bg {
			width: calc(50vw - (#{$content_width} / 4));
			min-width: 50%;
			height: 100%;
			box-sizing: border-box;
			position: absolute;
			background-color: #EFE9DE;
			left: 50%;
		}
	}

	@include media($tablet-protrait){
		.hi-body {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			.hi-body__img {
				margin-top: 0;
				width: 70%;
				max-width: 360px;
				z-index: 1;
				margin-bottom: 40px;
			}
			.hi-body__bg {
				height: 50%;
				width: 100%;
				bottom: 0%;
				z-index: -1;
				left: 0;
			}
		}
	}

	@include media($query:'(max-width: 1200px) and (orientation: landscape)'){
			.hi-body {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			width: 50%;
			height: 100%;
			position: relative;
			.hi-body__img {
				margin-top: 85px;
				animation: topFadeIn 1000ms alternate;
				width: 70%;
				max-width: 400px;
				min-width: 300px;
				z-index: 1;
				position: relative;
			}
			.hi-body__bg {
				width: calc(50vw - (#{$content_width} / 4));
				min-width: 50%;
				height: 100%;
				box-sizing: border-box;
				position: absolute;
				background-color: #EFE9DE;
				left: 50%;
			}
		}
	}

	@include media($phone){
		.hi-body {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			.hi-body__img {
				margin-top: 0;
				width: 70%;
				max-width: 360px;
				z-index: 1;
				margin-bottom: 40px;
			}
			.hi-body__bg {
				height: 50%;
				width: 100%;
				bottom: 0%;
				z-index: -1;
				left: 0;
			}
		}
	}
</style>