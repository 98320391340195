<template>
	<div class="sl-body">
		<div class="sl-title__wrapper">
			<h1>{{ title }}</h1>
		</div>
		<HeroImg class="sl-heroImg"/>
		<div class="sl-selector__wrapper">
			<p>Select your location below</p>
			<ul>
				<li v-for="(option,index) in selectOptions" :key="index">
					<a :href="option.url" target="_blank">
						<div class="sl-selector__option">
						<img class="sl-selector__icon" :src="option.icon" :alt="option.name">
						<div class="sl-selector__button abi-btn abi-btn-secondary">
							<div class="abi-btn-secondary__text-wrap">
								<p class="sl-selector__text">{{option.name}}</p>
							</div>
							<div class="abi-btn-secondary__icon-wrap">
								<img src="@/assets/imgs/Arrow-Right.svg" alt="arrow-right">
							</div>
						</div>
					</div>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import * as Constants from "@/constants";
import AUIcon from "@/assets/imgs/AU.png"
import NZIcon from "@/assets/imgs/NZ.png"
import UKIcon from "@/assets/imgs/GB.png"
import HeroImg from '@/components/HeroImg.vue'
export default {
	name:"SelectLocations",
	components:{
		HeroImg
	},
	data(){
		return {
			title:this.$route.path==="/"?Constants.ABI_URL["home"]['title']:
					this.$route.path==="/catalogue" || this.$route.path==="/catalogue/" ? Constants.ABI_URL["catalogue"]['title']:
					this.$route.path==="/product-samples" || this.$route.path==="/product-samples/" ? Constants.ABI_URL["shop-colour-sample-pack"]['title']:
					this.$route.path==="/trade-application" || this.$route.path==="/trade-application/" ? Constants.ABI_URL["trade-partner-application"]['title']:
					this.$route.path==="/warranty-and-product-enquiry" || this.$route.path==="/warranty-and-product-enquiry/"?Constants.ABI_URL["warranty"]['title'] : "",
			selectOptions:[
				{
					name:"Australia",
					icon:AUIcon,
					url:
					this.$route.path==="/"?Constants.ABI_URL["home"]['au']:
					this.$route.path==="/catalogue" || this.$route.path==="/catalogue/" ? Constants.ABI_URL["catalogue"]['au']:
					this.$route.path==="/product-samples" || this.$route.path==="/product-samples/" ? Constants.ABI_URL["shop-colour-sample-pack"]['au']:
					this.$route.path==="/trade-application" || this.$route.path==="/trade-application/" ? Constants.ABI_URL["trade-partner-application"]['au']:
					this.$route.path==="/warranty-and-product-enquiry" || this.$route.path==="/warranty-and-product-enquiry/"?Constants.ABI_URL["warranty"]['au'] : ""
				},
				{
					name:"New Zealand",
					icon:NZIcon,
					url:
					this.$route.path==="/"?Constants.ABI_URL["home"]['nz']:
					this.$route.path==="/catalogue" || this.$route.path==="/catalogue/"?Constants.ABI_URL["catalogue"]['nz']:
					this.$route.path==="/product-samples" || this.$route.path==="/product-samples/" ? Constants.ABI_URL["shop-colour-sample-pack"]['nz']:
					this.$route.path==="/trade-application" || this.$route.path==="/trade-application/" ? Constants.ABI_URL["trade-partner-application"]['nz']:
					this.$route.path==="/warranty-and-product-enquiry" || this.$route.path==="/warranty-and-product-enquiry/"?Constants.ABI_URL["warranty"]['nz'] : ""
				},
				{
					name:"United Kingdom",
					icon:UKIcon,
					url:
					this.$route.path==="/" ? Constants.ABI_URL["home"]['uk']:
					this.$route.path==="/catalogue" || this.$route.path==="/catalogue/" ? Constants.ABI_URL["catalogue"]['uk']:
					this.$route.path==="/product-samples" || this.$route.path==="/product-samples/" ? Constants.ABI_URL["shop-colour-sample-pack"]['uk']:
					this.$route.path==="/trade-application" || this.$route.path==="/trade-application/" ? Constants.ABI_URL["trade-partner-application"]['uk']:
					this.$route.path==="/warranty-and-product-enquiry" || this.$route.path==="/warranty-and-product-enquiry/" ? Constants.ABI_URL["warranty"]['uk'] : ""
				},
			]
		}
	},
}
</script>

<style lang="scss">
@import '@/assets/css/theme.scss';
	.sl-body::-webkit-scrollbar {
		display: none;
	}

	.sl-body {
		overflow: auto;
		box-sizing: border-box;
		padding-left: 40px;
		display: flex;
		align-items: center;;
		justify-content: flex-start;
		width: 50%;
		height: 100%;
		flex-direction: column;
		.sl-title__wrapper {
			margin-top: 85px;
			font-size: 65px;
			width: 550px;
			animation: leftFadeIn 1000ms alternate;
			position: relative;
			h1 {
				font-weight: normal;
				margin: 0 0 52px 0;
				font-size: $font_lg;
				font-family: 'Agatho Light', 'sans-serif';;
				color: #434243;
			}
		}
		.sl-heroImg {
			display: none;
		}
		.sl-selector__wrapper{
			width: 70%;
			min-width: 550px;
			font-size: 30px;
			animation: leftFadeIn 1000ms alternate;
			animation-delay: 200ms;
			animation-fill-mode: both;
			opacity: 0;
			position: relative;
			p{
				font-size: $font_sm;
				font-family: 'Montserrat', sans-serif;
				margin-bottom: 52px;
				margin-top: 0;
			}
			ul {
				list-style: none;
				padding: 0%;
				a {
					text-decoration: none;
					.sl-selector__option {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin:0 0 48px 0;
						.sl-selector__icon {
							width: 50px;
							height: 50px;
						}
						.sl-selector__button {
							margin-left: 30px;
							.sl-selector__text {
								cursor: pointer;
								font-family: 'Montserrat', sans-serif;
								margin: 0;
							}
						}

					}
				}
			}

		}
	}

	@include media($query:'(max-width: 1200px) and (orientation: landscape)'){
		.sl-body {
			width: 100%;
			padding: 0%;
			.sl-title__wrapper {
				h1{
					font-size: 65px;
				}
			}
			.sl-selector__wrapper {
				p{
					margin-top: 48px;
				}
				ul {
					margin: 0;
				}
			}
		}
	}

	@include media($tablet-protrait){
		.sl-body {
			width: 100%;
			padding: 0%;
			.sl-title__wrapper {
				text-align: center;
				h1{
					font-size: 45px;
				}
			}
			.sl-heroImg {
				display: flex;
			}
			.sl-selector__wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				p{
					margin-top: 48px;
				}
				ul {
					margin: 0;
				}
			}
		}
	}

	@include media($phone){
		.sl-body {
			width: 100%;
			padding: 0%;
			.sl-title__wrapper {
				width: 100%;
				display: flex;
				text-align: center;
				justify-content: center;
				margin-bottom: 40px;
				animation:none;
				h1{
					font-size: 45px;
					width: 80%;
					padding: 0%;
					margin: 0%;
				}
			}
			.sl-heroImg {
				display: flex;
			}
			.sl-selector__wrapper {
				width: 100%;
				min-width: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				p {
					margin-top: 48px;
				}
				ul {
					margin: 0;
				}
				.sl-selector__option {
					margin:0 0 48px 0;
				}
			}
		}
	}
</style>